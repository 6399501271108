import React from "react"
import { Link } from "gatsby"

import Img from "gatsby-image"
import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"
import { Container, Row, Col } from "react-grid-system"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faPlayCircle, faEnvelope, faChevronRight, faEye, faCheck } from "@fortawesome/free-solid-svg-icons"

export default class Page extends React.Component {
  render() {
    return (
      <Layout container={true} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Ablaufotimpierung durch Software Entwicklung"></SEO>
        <div className="loosepage text-center">
          <h1>Software Entwicklung</h1>
          <p style={{ maxWidth: 500, margin: "auto" }}>Ihre Software - Lösung. <span className="muted">Von Ticketsystem über Kassenabrechnungen bis zum Buchverleih bieten wir Ihnen alle Arten von speziellen Softwarelösung genau für Ihren Anwendungsbereich.</span></p>
        </div>
        <div style={{ margin: "auto", maxWidth: "800px" }}>
          <Img style={{ borderRadius: "7px" }}                     src={require("../../images/services/custom-software.jpg")}/>

        </div>
        <div className="text-center" style={{margin:20}}>
          <Link className="btn btn-primary-outline" to="/stories" target="_blank"><FontAwesomeIcon icon={faEye}></FontAwesomeIcon> Beispiel Software</Link>
          <Link className="btn btn-primary-outline" to="/mehr-erfahren">Mehr erfahren <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>
        <br/>
        <div className="okbox">
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Betriebssystemunabhängige Web-Apps </div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> React/Vue, Gatsby/Gridsome, PHP/Laravel </div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Windows, MacOS, Linux </div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Amazon Web Services als PaaS</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> jQuery, Bootstrap, Vuetify, Semantic UI, Antdesign</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Netlify/Cloudflare Deployment </div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Sandbox Touchscreen - Systeme</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Inklusive Hardware (z.B. QR-Code Scanner)</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> Vollständige Einrichtung und Wartung</div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> <Link className="text-lime" to="/services/apps">Smartphone Apps für Android und iOS <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link></div>
          <div><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon> ab 1850€</div>
        </div>
        <br />
        <br />
        <br />
      </Layout >
    )
  }
}


